import API from '../components/API'
import { getJwtString } from '../components/Auth'

// Get hace una petición GET al backend.
//
// @param uri string sin "/".
// @param queryParams string debe iniciar con "?".
//
// @return body Object JSON que llega del backend.
// @return error Object si el backend retorna un error.
const GET = async (uri, queryParams = '') => {
  const resp = {
    status: 0,
    body: {}
  }

  try {
    const response = await fetch(API(uri, queryParams), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getJwtString()
      },
      mode: 'cors',
      cache: 'default'
    })

    resp.status = response.status

    if (resp.status !== 204) {
      resp.body = await response.json()
    } else {
      resp.body = null
    }
  } catch (err) {
    resp.status = 502
    resp.body.message = err.message
  }

  return resp
}

export default GET
