import React, { useEffect, useState, useContext } from 'react'
import Layout from '../components/Layout'
import useUsers from '../hooks/users'
import { Link } from 'gatsby'
import moment from 'moment-timezone'

import './users.scss'

import { isBrowser, hasPermission, hasRole } from '../components/Auth'
import PUSH from '../dao/push'
import { toast } from 'react-toastify'
import { getRoles } from '../services/api/roles'
import { Context } from '../Context'

export default function UsersPage () {
  const { isAuth } = useContext(Context)

  const [users] = useUsers([])
  const [roles, setRoles] = useState([])

  useEffect(() => {
    getRoles()
      .then(res => {
        setRoles(res.data)
      })
      .catch(err => console.dir(err))
  }, [])

  if (!isAuth || !hasRole(1)) {
    if (isBrowser()) {
      window.location.href = '/'
    }

    return (
      <Layout withoutHeader className='page-users'>
        Cargando...
      </Layout>
    )
  }

  function submitResetToken2fa (id) {
    PUSH('PUT', 'users', { id }, 'action=reset_secret_key').then(res => {
      if (res.status >= 400) {
        toast.error(res.body.message)
      } else {
        toast.success(res.body.message)
      }
    })
  }

  return (
    <Layout className='page-users'>
      <h1>Lista de usuarios</h1>

      <table className='table'>
        <thead>
          <tr>
            <th>ID</th>
            <th>Nombre</th>
            <th>Correo</th>
            <th>Sesión más reciente</th>
            <th>Roles</th>
            <th>Admin</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => {
            return (
              <tr key={user.id}>
                <td>{user.id}</td>
                <td>
                  {user.name} {user.name2} {user.lastname}
                </td>
                <td>{user.email}</td>
                <td>
                  {!user.recent_login ? '-'
                    : moment
                      .utc(user.recent_login)
                      .local()
                      .format('YYYY-MM-DD HH:mm:ss')}
                </td>
                <td>
                  {user.roles
                    ? user.roles
                      .map(rolId => {
                        const rol = roles.find(r => r.id === rolId)
                        return rol ? rol.name : `[Rol ${rolId}]`
                      })
                      .join(', ')
                    : '[No tiene rol]'}
                </td>
                <td>
                  {/* update_user */}
                  {hasPermission(97) ? (
                    <>
                      <button
                        className='btn'
                        onClick={() => submitResetToken2fa(user.id)}
                      >
                        Resetear token2fa
                      </button>
                      <Link
                        to={`/edit-user-roles/?user_id=${user.id}`}
                        className='btn'
                      >
                        Editar roles
                      </Link>
                      <Link
                        to={`/assign-force/?user_id=${user.id}`}
                        className='btn'
                      >
                        Asignar fuerza
                      </Link>
                    </>
                  ) : null}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </Layout>
  )
}
